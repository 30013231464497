import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wet etching and cleaning tools`}</h4>
    <p>{`Etching and cleaning tools have two main types: dry and wet. Wet etching tools, using liquids, are less commonly used, and largely for cleaning wafers. Wet etching has advantages compared to dry etching-it is cheaper, risks less damage to substrates, and is more selective, i.e., it can etch a particular material without unintentionally etching nearby materials. However, it is slower and typically cannot etch differently depending on the direction of etch, making it difficult to form complex structures. Therefore, wet etching is not typically used for etching the smallest features in advanced chips.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      